<template>
  <div class="accounts">
    <div class="container my-5">
      <div class="row">
        <div class="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
          <div class="media mb-5">
            <font-awesome-icon icon="check-circle" size="4x" class="text-primary mr-2 mr-sm-4 align-self-center" />
            <div class="media-body">
              <h3>
                Thank you for your order!
              </h3>
              We've sent a confirmation email to {{ email }}. If you have questions about your order, contact us at
              <a class="btn-link" href="mailto:support@solidprofessor.com">support@solidprofessor.com.</a>
            </div>
          </div>
          <hr>
          <div class="my-5">
            <div class="row">
              <div class="col-6">
                <div class="mb-2">
                  <div class="font-weight-semibold">
                    Order number
                  </div>
                  <div class="text-muted">
                    {{ order.id }}
                  </div>
                </div>
                <div class="mb-2">
                  <div class="font-weight-semibold">
                    Payment method
                  </div>
                  <div class="text-muted text-capitalize">
                    {{ order.paymentMethod }}
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="float-right">
                  <div class="mb-2">
                    <div class="font-weight-semibold">
                      Item
                    </div>
                    <div class="text-muted">
                      Student Membership
                    </div>
                  </div>
                  <div class="mb-2">
                    <div class="font-weight-semibold">
                      Total
                    </div>
                    <div class="text-muted">
                      {{ order.total | formatCurrency }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr>
          <div class="text-center mt-5">
            <a :href="assignmentUrl" class="btn btn-lg btn-primary">Continue to my assignment</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store';

export default {
  name: 'StudentConfirmation',
  beforeRouteEnter(to, from, next) {
    // route guard - must have completed previous step
    if (!store.getters['studentStore/getOrder']) {
      next({name: 'StudentAccount'});
    } else {
      next();
    }
  },
  computed: {
    order() {
      return this.$store.getters['studentStore/getOrder'];
    },
    email() {
      return this.$store.getters['studentStore/getEmail'];
    },
    returnURL () {
      return this.$store.getters['params/getReturnURL'];
    },
    assignmentUrl() {
      if (this.returnURL) {
        return this.returnURL;
      } else {
        return this.SPENV.APP_URL + '/lmsapp/multigroups/list';
      }
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
